import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
let Questions = class Questions extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            wishlist_id: '',
            questions: [
                {
                    question_id: '',
                    question: '',
                    answer: '',
                }
            ]
        };
        this.errors = null;
        this.disabled = false;
        this.loading = true;
        this.error = '';
        this.coverImageDesc = '';
    }
    created() {
    }
    async mounted() {
        await this.$store.dispatch('questions/getQuestions');
        this.setUpForm();
        this.loading = false;
    }
    async setUpForm() {
        this.form.wishlist_id = this.wishlist.id;
        this.questions.forEach((question, index) => {
            let data = {
                question_id: question.id,
                question: question.question,
                answer: '',
            };
            this.form.questions[index] = data;
        });
    }
    async backToWishlistPage() {
        this.$router.push({ path: `/wishlist/${this.wishlist.slug}` });
    }
    async submit() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('wishlist_id', this.form.wishlist_id);
        // make string array out of questions for formData
        this.form.questions.forEach((question, index) => {
            formData.append(`questions[${index}][question_id]`, question.question_id);
            formData.append(`questions[${index}][question]`, question.question);
            formData.append(`questions[${index}][answer]`, question.answer);
        });
        try {
            this.loading = true;
            this.disabled = true;
            const resp = await this.$store.dispatch('questions/submitQuestions', formData);
            this.$router.push({ path: `/questions/thank-you` });
            this.disabled = false;
            this.loading = false;
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.error = e.message;
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
};
__decorate([
    Getter('getQuestions', { namespace: 'questions' })
], Questions.prototype, "questions", void 0);
__decorate([
    Getter('activeWishlist', { namespace: 'wishlists' })
], Questions.prototype, "wishlist", void 0);
Questions = __decorate([
    Component({
        components: {
            Btn,
            TextInput,
            CoverImage,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | questions',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic questions',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/questions` }],
            };
        },
    })
], Questions);
export default Questions;
