import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
// components
import Btn from '@/components/Button/Btn.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
let ThankYouPage = class ThankYouPage extends Vue {
    constructor() {
        super(...arguments);
        this.coverImageDesc = '';
    }
    async back() {
        this.$router.push({ path: `/wishlists` });
    }
};
ThankYouPage = __decorate([
    Component({
        components: {
            Btn,
            CoverImage,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | thank-you',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic thank-you',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/questions/thank-you` }],
            };
        },
    })
], ThankYouPage);
export default ThankYouPage;
